import React from "react";
import styles from "./Home.module.css";
import {  useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Home = () => {
  const user = JSON.parse(localStorage.getItem("profile"));

  const history = useNavigate();


  return (
    <>
    <Header/>
    <div className={styles.pageContainer}>
      <section className={styles.hero}>
        <h1>Create Professional Invoices Effortlessly</h1>
        {/* <div className={styles.paragraph}></div> */}
        {!user && (
          <div className={styles.header2}>
            <button
              onClick={() => history("/login")}
              className={styles.login}
            >
              Get started
            </button>
          </div>
        )}
      </section>
    </div>
    <Footer/>
    </>
    
  );
};

export default Home;
