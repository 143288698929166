import React from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import SnackbarProvider from "react-simple-snackbar";
import Home from "./components/Home/Home.js";
import NotFound from "./components/Home/NotFound.js";
import Invoice from "./components/Invoice/Invoice.js";
import InvoiceDetails from "./components/InvoiceDetails/InvoiceDetails.js";
import ClientList from "./components/Clients/ClientList.js";
import NavBar from "./components/NavBar/NavBar.js";
import Login from "./components/Login/Login.js";
import Dashboard from "./components/Dashboard/Dashboard.js";
import Footer from "./components/Footer/Footer.js";
import Header from "./components/Header/Header.js";
import Settings from "./components/Settings/Settings.js";
import Forgot from "./components/Password/Forgot.js";
import Reset from "./components/Password/Reset.js";
import Invoices from "./components/Invoices/Invoices.js";
import Payment from "./components/Payments/Payment.js";
import PaymentSuccess from "./components/Payments/PaymentSuccess.js";
import PlanExpired from "./components/Home/PlanExpired.js";


function App() {
  const HomePage = () => {
    const user = JSON.parse(localStorage.getItem("profile"));

    if (!user) {
      return <Navigate to="/home" />;
    }

    if(!user?.userProfile?.isEnabled){
      return <Navigate to="/expired" />;
    }

    return (
      <>
        <NavBar />
        <Header />
        <Outlet />
        <Footer />
      </>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
      children: [
        {
          path: "/invoice",
          element: <Invoice />,
        },
        {
          path: "/edit/invoice/:id",
          element: <Invoice />,
        },
        {
          path: "/overdue/:id",
          element: <Invoice />,
        },
        {
          path: "/paid/:id",
          element: <Invoice />,
        },
        
        {
          path: "/invoices",
          element: <Invoices />,
        },
        {
          path: "/overdues",
          element: <Invoices />,
        },
        {
          path: "/paid",
          element: <Invoices />,
        },
        {
          path: "/unpaid",
          element: <Invoices />,
        },
        {
          path: "/total",
          element: <Invoices />,
        },
        {
          path: "/partial",
          element: <Invoices />,
        },

        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/customers",
          element: <ClientList />,
        },
        {
          path: "/forgot",
          element: <Forgot />,
        },
        {
          path: "/reset/:token",
          element: <Reset />,
        },
        
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/expired",
      element:  <PlanExpired/>,
    },
    {
      path: "/invoice/:id",
      element: <InvoiceDetails />,
    },
    {
      path: "/payment/:id",
      element: <Payment />,
    },
    {
      path: "/payment/success/:id",
      element: <PaymentSuccess />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <>
      <SnackbarProvider>
        <RouterProvider router={router} />
      </SnackbarProvider>
    </>
  );
}

export default App;
