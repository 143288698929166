import React, { useState, useEffect, useRef } from "react";
// import "../../../node_modules/react-progress-button/react-progress-button.css"
import { useSnackbar } from "react-simple-snackbar";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "../../initialState";
import { getInvoice } from "../../actions/invoiceActions";
import { toCommas } from "../../utils/utils";
import styles from "./InvoiceDetails.module.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { Container, Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Spinner from "../Spinner/Spinner";

import ProgressButton from "react-progress-button";
import axios from "axios";
import Modal from "../Payments/Modal";
import PaymentHistory from "./PaymentHistory";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const InvoiceDetails = () => {
  const location = useLocation();
  const componentRef = useRef();

  const [invoiceData, setInvoiceData] = useState(initialState);
  const [rates, setRates] = useState(0);
  const [vat, setVat] = useState(0);
  const [hsn, setHsn] = useState(0);
  const [currency, setCurrency] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [client, setClient] = useState([]);
  const [type, setType] = React.useState("");
  const [status, setStatus] = useState("");
  const [company, setCompany] = useState({});
  const { id } = useParams();
  const { invoice } = useSelector((state) => state.invoices);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [sendStatus, setSendStatus] = useState(null);
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [sendWaStatus, setSendWaStatus] = useState(null);

  const [openSnackbar] = useSnackbar();
  const user = JSON.parse(localStorage.getItem("profile"));

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    table: {
      minWidth: 650,
    },

    headerContainer: {
      // display: 'flex'
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(1),
      backgroundColor: "#f2f2f2",
      borderRadius: "10px 10px 0px 0px",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    dispatch(getInvoice(id));
  }, [id, dispatch, location]);

  useEffect(() => {
    if (invoice) {
      //Automatically set the default invoice values as the ones in the invoice to be updated
      setInvoiceData(invoice);
      setRates(invoice.rates);
      setClient(invoice.client);
      setType(invoice.type);
      setStatus(invoice.status);
      setSelectedDate(invoice.dueDate);
      setVat(invoice.vat);
      setHsn(invoice.hsn);
      setCurrency(invoice.currency);
      setSubTotal(invoice.subTotal);
      setTotal(invoice.total);
      setCompany(invoice?.businessDetails?.data?.data);
    }
  }, [invoice]);

  //Get the total amount paid
  let totalAmountReceived = 0;
  for (var i = 0; i < invoice?.paymentRecords?.length; i++) {
    totalAmountReceived += Number(invoice?.paymentRecords[i]?.amountPaid);
  }

  const editInvoice = (id) => {
    history(`/edit/invoice/${id}`);
  };

  const overDuesInvoice = (id) => {
    history(`/overdue/${id}`);
  };

  const createAndDownloadPdf = () => {
    setDownloadStatus("loading");
    const ids = `invoice-${invoice.client.name}-${invoice.invoiceNumber}.pdf`;
    axios
      .post(`${process.env.REACT_APP_INVOICE_API}/api/v2/invoices/generate`, {
        ids: invoice._id,
        name: invoice.client.name,
        gstno: invoice.client.gstno,
        flat: invoice.client.flat,
        flatarea: invoice.client.flatarea,
        floor: invoice.client.floor,
        block: invoice.client.block,
        phone: invoice.client.phone,
        email: invoice.client.email,
        dueDate: invoice.dueDate,
        date: invoice.createdAt,
        id: invoice.invoiceNumber,
        notes: invoice.notes,
        subTotal: toCommas(invoice.subTotal),
        total: toCommas(invoice.total),
        type: invoice.type,
        vat: invoice.vat,
        hsn: invoice.hsn,
        items: invoice.items,
        status: invoice.status,
        currency: invoice.currency,
        totalAmountReceived: toCommas(totalAmountReceived),
        balanceDue: toCommas(total - totalAmountReceived),
        company: company,
      })
      .then((response) => {
        if (!response.data || response.data.status !== "success") {
          throw new Error("No data received or operation failed");
        }

        const downloadUrl = response.data.url;

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", ids);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadStatus("success");

        // const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        // saveAs(pdfBlob, ids);

        // setDownloadStatus("success");
      })
      .catch((error) => {
        setDownloadStatus("error");
        // Enhanced error handling

        console.error(error.message);
      });
  };

  const sendInvoiceWhatsapp = (e) => {
    e.preventDefault();
    setSendWaStatus("loading");
    axios
      .post(`${process.env.REACT_APP_INVOICE_API}/api/v2/invoices/generate`, {
        ids: invoice._id,
        name: invoice.client.name,
        gstno: invoice.client.gstno,
        flat: invoice.client.flat,
        flatarea: invoice.client.flatarea,
        floor: invoice.client.floor,
        block: invoice.client.block,
        phone: invoice.client.phone,
        email: invoice.client.email,
        dueDate: invoice.dueDate,
        date: invoice.createdAt,
        id: invoice.invoiceNumber,
        notes: invoice.notes,
        subTotal: toCommas(invoice.subTotal),

        type: invoice.type,
        vat: invoice.vat,
        hsn: invoice.hsn,
        items: invoice.items,
        status: invoice.status,
        currency: invoice.currency,
        total: toCommas(invoice.total + vat),
        totalAmountReceived: toCommas(totalAmountReceived),
        balanceDue: toCommas(total - totalAmountReceived),
        company: company,
      })
      .then((response) => {
        const url = response.data.url;
        axios
          .post(
            `${process.env.REACT_APP_INVOICE_API}/api/v2/invoices/send/wa/${user?.userProfile?.token}`,
            {
              invoice,
              company,
              url,
            }
          )
          .then((response) => {
            if (response.data.status === "success") {
              console.log(response.data.message);
              setSendWaStatus("success");
            } else {
              console.log(response.data.message);
              setSendWaStatus("error");
            }
          })
          .catch((error) => {
            console.log("Error :", error.message);
            setSendWaStatus("error");
          });
      })
      .catch((error) => {
        console.log("Error from server:", error.message);
        setSendWaStatus("error");
      });
  };

  const sendInvoiceViaMail = (e) => {
    e.preventDefault();
    setSendStatus("loading");
    axios
      .post(`${process.env.REACT_APP_INVOICE_API}/api/v2/invoices/send/email`, {
        ids: invoice._id,
        name: invoice.client.name,
        gstno: invoice.client.gstno,
        flat: invoice.client.flat,
        flatarea: invoice.client.flatarea,
        floor: invoice.client.floor,
        block: invoice.client.block,
        phone: invoice.client.phone,
        email: invoice.client.email,
        dueDate: invoice.dueDate,
        date: invoice.createdAt,
        id: invoice.invoiceNumber,
        notes: invoice.notes,
        subTotal: toCommas(invoice.subTotal),

        type: invoice.type,
        vat: invoice.vat,
        hsn: invoice.hsn,
        items: invoice.items,
        status: invoice.status,
        currency: invoice.currency,
        total: toCommas(invoice.total + vat),
        totalAmountReceived: toCommas(totalAmountReceived),
        balanceDue: toCommas(total - totalAmountReceived),
        link: `${process.env.REACT_APP_URL}/invoice/${invoice._id}`,
        company: company,
      })
      // .then(() => console.log("invoice sent successfully"))
      .then((response) => {
        // Handle success response from the server
        console.log("Response from server:", response.data);
        setSendStatus("success");
      })
      .catch((error) => {
        // Handle error response from the server
        console.log("Error from server:", error.response.data.error);
        setSendStatus("error");
      });
  };

  const iconSize = {
    height: "18px",
    width: "18px",
    marginRight: "10px",
    color: "gray",
  };
  const [open, setOpen] = useState(false);

  function checkStatus() {
    return totalAmountReceived >= total
      ? "green"
      : status === "Partial"
      ? "#1976d2"
      : status === "Paid"
      ? "green"
      : status === "Unpaid"
      ? "red"
      : "red";
  }

  if (!invoice) {
    return <Spinner />;
  }

  const contactAddress =
    invoice?.businessDetails?.data?.data?.contactAddress || "";

  const formatAddress = (address) => {
    // Split the address by commas
    const segments = address.split(",");

    // Group segments so that every even number of commas results in a new line
    const newSegments = segments.reduce((acc, segment, index) => {
      const groupIndex = Math.floor(index / 2);
      if (!acc[groupIndex]) {
        acc[groupIndex] = [];
      }
      acc[groupIndex].push(segment.trim());
      return acc;
    }, []);

    // Join the segments back together with line breaks
    return newSegments.map((group) => group.join(", ")).join("\n");
  };

  const address = formatAddress(contactAddress);

  return (
    <>
      <Header />
      <div className={styles.PageLayout}>
        <div className={styles.buttonsTop}>
          {invoice?.creator?.includes(
            user?.result?._id || user?.result?.googleId
          ) && (
            <>
              <button
                className={styles.btn}
                onClick={() => editInvoice(invoiceData._id)}
              >
                <BorderColorIcon style={iconSize} />
                Edit Invoice
              </button>

              <button
                className={styles.btn}
                onClick={() => overDuesInvoice(invoiceData._id)}
              >
                <ReportProblemIcon style={iconSize} />
                Add Overdues Fine
              </button>
              <button
                className={styles.btn}
                onClick={() => setOpen((prev) => !prev)}
              >
                <MonetizationOnIcon style={iconSize} />
                Record Payment
              </button>
            </>
          )}
        </div>

        <div className={styles.buttonsBtm}>
          <ProgressButton
            onClick={createAndDownloadPdf}
            state={downloadStatus}
            onSuccess={() => openSnackbar("Download successfully")}
          >
            Save as PDF
          </ProgressButton>
          {invoice?.creator?.includes(
            user?.result?._id || user?.result?.googleId
          ) && (
            <ProgressButton
              onClick={sendInvoiceViaMail}
              state={sendStatus}
              onSuccess={() => openSnackbar("Invoice sent successfully")}
            >
              Send via Email
            </ProgressButton>
          )}

          {user?.userProfile?.token && (
            <ProgressButton
              onClick={sendInvoiceWhatsapp}
              state={sendWaStatus}
              onSuccess={() => openSnackbar("Invoice sent successfully")}
            >
              Send via Whatsapp
            </ProgressButton>
          )}
        </div>

        {invoice?.paymentRecords.length !== 0 && (
          <PaymentHistory paymentRecords={invoiceData?.paymentRecords} />
        )}

        <Modal open={open} setOpen={setOpen} invoice={invoice} />
        <div className={styles.invoiceLayout} ref={componentRef}>
          <Container className={classes.headerContainer}>
            <Grid
              container
              justifyContent="space-between"
              style={{ padding: "30px 0px" }}
            >
              {!invoice?.creator?.includes(
                user?.result._id || user?.result?.googleId
              ) ? (
                <Grid item></Grid>
              ) : (
                <Grid
                  item
                  onClick={() => history("/settings")}
                  style={{ cursor: "pointer" }}
                >
                  {company?.logo ? (
                    <img
                      src={company?.logo}
                      alt="Logo"
                      className={styles.logo}
                    />
                  ) : (
                    <h2>
                      {invoice?.businessDetails?.data?.data?.businessName}
                    </h2>
                  )}
                </Grid>
              )}
              <Grid item style={{ marginRight: 40, textAlign: "right" }}>
                <Typography
                  style={{
                    lineSpacing: 1,
                    fontSize: 45,
                    fontWeight: 700,
                    color: "gray",
                  }}
                >
                  {Number(total - totalAmountReceived) <= 0 ? "Invoice" : type}
                </Typography>
                <Typography variant="overline" style={{ color: "gray" }}>
                  No:{" "}
                </Typography>
                <Typography variant="body2">
                  {invoiceData?.invoiceNumber}
                </Typography>
              </Grid>
            </Grid>
          </Container>
          <Divider />
          <Container>
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: "40px" }}
            >
              <Grid item>
                {invoice?.creator?.includes(user?.result._id) && (
                  <Container style={{ marginBottom: "20px" }}>
                    <Typography
                      variant="overline"
                      style={{ color: "gray" }}
                      gutterBottom
                    >
                      From
                    </Typography>
                    <Typography variant="subtitle2">
                      {invoice?.businessDetails?.data?.data?.businessName}
                    </Typography>
                    <Typography variant="subtitle2">
                      {invoice?.businessDetails?.data?.data?.gstno}
                    </Typography>
                    <Typography variant="subtitle2">
                      {invoice?.businessDetails?.data?.data?.email}
                    </Typography>
                    <Typography variant="subtitle2">
                      {" "}
                      {invoice?.businessDetails?.data?.data?.phoneNumber}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {address.split("\n").map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                      {/* {invoice?.businessDetails?.data?.data?.contactAddress} */}
                    </Typography>
                  </Container>
                )}

                <Container>
                  <Typography
                    variant="overline"
                    style={{ color: "gray", paddingRight: "3px" }}
                    gutterBottom
                  >
                    Bill to
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    Name: {client?.name}
                  </Typography>

                  <Typography variant="subtitle2">
                    Flat: {client?.flat}
                  </Typography>
                  <Typography variant="subtitle2">
                    Floor: {client?.floor}
                  </Typography>
                  <Typography variant="subtitle2">
                    Block: {client?.block}
                  </Typography>
                  <Typography variant="subtitle2">
                    Email: {client?.email}
                  </Typography>
                  <Typography variant="subtitle2">
                    Phone: {client?.phone}
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    GST: {client?.gstno}
                  </Typography>
                  <br />
                </Container>
              </Grid>

              <Grid item style={{ marginRight: 20, textAlign: "right" }}>
                <Typography
                  variant="overline"
                  style={{ color: "gray" }}
                  gutterBottom
                >
                  Status
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ color: checkStatus() }}
                >
                  {totalAmountReceived >= total ? "Paid" : status}
                </Typography>
                <Typography variant="overline" gutterBottom>
                  HSN no.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {hsn}
                </Typography>

                <Typography
                  variant="overline"
                  style={{ color: "gray" }}
                  gutterBottom
                >
                  Date
                </Typography>
                {location.pathname.includes("/invoice") ? (
                  <Typography variant="body2" gutterBottom>
                    {moment(invoice?.createdAt).format("ll")}
                  </Typography>
                ) : (
                  <Typography variant="body2" gutterBottom>
                    {moment().format("ll")}
                  </Typography>
                )}

                <Typography
                  variant="overline"
                  style={{ color: "gray" }}
                  gutterBottom
                >
                  Due Date
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {selectedDate
                    ? moment(selectedDate).format("MMM DD, YYYY")
                    : "27th Sep 2021"}
                </Typography>
                <Typography variant="overline" gutterBottom>
                  Amount
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {currency} {toCommas(total)}
                </Typography>
              </Grid>
            </Grid>
          </Container>

          <form>
            <div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell>Area (Sq.ft)</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Disc(%)</TableCell>
                      <TableCell>Late Fee(%)</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceData?.items?.map((itemField, index) => (
                      <TableRow key={index}>
                        <TableCell scope="row" style={{ width: "40%" }}>
                          {" "}
                          <InputBase
                            style={{ width: "100%" }}
                            outline="none"
                            sx={{ ml: 1, flex: 1 }}
                            type="text"
                            name="itemName"
                            value={itemField.itemName}
                            placeholder="Item name or description"
                            readOnly
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="quantity"
                            value={
                              itemField?.quantity
                                ? itemField?.quantity
                                : client?.flatarea
                            }
                            placeholder="0"
                            readOnly
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="unitPrice"
                            value={itemField?.unitPrice}
                            placeholder="0"
                            readOnly
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="discount"
                            value={
                              itemField?.discount ? itemField?.discount : 0
                            }
                            readOnly
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="overDues"
                            value={
                              itemField?.overDues ? itemField?.overDues : 0
                            }
                            readOnly
                          />{" "}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            type="number"
                            name="amount"
                            value={(
                              client?.flatarea * itemField.unitPrice +
                              (client?.flatarea *
                                itemField.unitPrice *
                                (itemField.overDues ? itemField.overDues : 0)) /
                                100 -
                              (client?.flatarea *
                                itemField.unitPrice *
                                itemField.discount) /
                                100
                            ).toFixed(2)}
                            readOnly
                          />{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className={styles.addButton}></div>
            </div>

            <div className={styles.invoiceSummary}>
              <div className={styles.summary}>Invoice Summary</div>
              <div className={styles.summaryItem}>
                <p>Subtotal:</p>
                <h4>{subTotal}</h4>
              </div>
              <div className={styles.summaryItem}>
                <p>{`GST(${rates}%):`}</p>
                <h4>{vat}</h4>
              </div>
              <div className={styles.summaryItem}>
                <p>Total</p>
                <h4>
                  {currency} {toCommas(total)}
                </h4>
              </div>
              <div className={styles.summaryItem}>
                <p>Paid</p>
                <h4>
                  {currency} {toCommas(totalAmountReceived)}
                </h4>
              </div>

              <div className={styles.summaryItem}>
                <p>Balance</p>
                <h4
                  style={{
                    color: "black",
                    fontSize: "18px",
                    lineHeight: "8px",
                  }}
                >
                  {currency} {toCommas(total - totalAmountReceived)}
                </h4>
              </div>
            </div>

            <div className={styles.note}>
              <h4 style={{ marginLeft: "-10px" }}>Note|Payment Info</h4>
              <p style={{ fontSize: "14px" }}>{invoiceData.notes}</p>
            </div>

            {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default InvoiceDetails;
