import React from "react";
import {
  Container,
  Typography,
  Button,
  makeStyles,
 
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import UnAuthorized from "../svgIcons/UnAuthorized";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(3),
  },

  icon: {
    fontSize: "6rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "8rem",
    },
  },

  title: {
    fontSize: "2rem",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  subtitle: {
    fontSize: "1.2rem",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  button: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1.5, 3),
  },
}));

const PlanExpired = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const navigate = useNavigate();

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/home");
  };

  return (
    <>
      <Container className={classes.root}>
        <Typography className={classes.title} color="textPrimary">
          Plan Expired!!!
        </Typography>
        <br/>
        <UnAuthorized />
        <br/>
        <Typography className={classes.subtitle} color="textPrimary">
          Please renew your plan to continue using our services.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => logout()}
        >
          Logout
        </Button>
      </Container>
    </>
  );
};

export default PlanExpired;
